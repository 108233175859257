import axios from "axios"
import api from "../api"

export const state = {
  isModal: false,
  data: [],
  event: {},
  formData: new FormData()
}

export const mutations = {
  SET_EVENTS_MODAL (state, value) {
    state.isModal = value
  },
  SET_EVENT (state, _id) {
    const event = state.data.find((e) => e._id === _id)
    state.event = JSON.parse(JSON.stringify(event))
  },
  SET_EVENTS_DATA (state, data) {
    state.data = data
  },
  UPDATE_EVENT_KEY (state, {name, value}) {
    state.event[name] = value
  },
  RESET_FORM_DATA (state) {
    state.event = {}
    state.formData = new FormData()
  },
  UPDATE_EVENT_IN_LIST (state, event) {
    const index = state.data.findIndex((e) => e._id === event._id)
    state.data[index] = JSON.parse(JSON.stringify(event))
  },
  REMOVE_EVENT_FROM_LIST (state, id) {
    const index = state.data.findIndex((e) => e._id === id)
    state.data.splice(index, 1)
  },
  ADD_EVENT (state, event) {
    state.data.push(event)
  },
  ADD_FILE (state, formData) {
    state.formData = formData
  },
  REMOVE_FILE (state, file) {
    state.formData.delete(file)
  }
}

export const actions = {
  async get_events ({ commit, rootState }) {
    try {
      const id = rootState.restaurant_id
      const { data } = await axios(`${api.events}?id=${rootState.auth.user?.role || id}`)
      commit('SET_EVENTS_DATA', data)
    } catch (e) {}
  },
  async add_event ({ state, commit, rootState }) {
    try {
      const id = rootState.restaurant_id
      const url = `${api.events}?id=${rootState.auth.user?.role || id}`
      Object.keys(state.event).forEach((key) => {
        state.formData.append(key, state.event[key])
      })
      const {data: event} = await axios.post(url, state.formData)
      commit('ADD_EVENT', event)
      commit('RESET_FORM_DATA')
      commit('SET_EVENTS_MODAL', false)
    } catch (e) {
      commit('RESET_FORM_DATA')
    }
  },
  async update_event ({ state, commit, rootState }) {
    try {
      const id = rootState.restaurant_id
      const url = `${api.events}?id=${rootState.auth.user?.role || id}`
      Object.keys(state.event).forEach((key) => {
        state.formData.append(key, state.event[key])
      })
      const { data } = await axios.put(url, state.formData)
      commit('UPDATE_EVENT_IN_LIST', data)
      commit('RESET_FORM_DATA')
      commit('SET_EVENTS_MODAL', false)
    } catch (e) {
      commit('RESET_FORM_DATA')
    }
  },
  async delete_event ({ state, commit, rootState }) {
    try {
      const id = rootState.restaurant_id
      const url = `${api.events}?id=${rootState.auth.user?.role || id}&eventId=${state.event._id}`
      await axios.delete(url)
      commit('REMOVE_EVENT_FROM_LIST', state.event._id)
      commit('RESET_FORM_DATA')
      commit('SET_EVENTS_MODAL', false)
    } catch (e) {
      commit('RESET_FORM_DATA')
    }
  },
}