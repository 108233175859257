import axios from 'axios'
import api from '../api'
import ShortUniqueId from 'short-unique-id'

const uid = new ShortUniqueId({ length: 6 })

export const state = {
  categories: [],
  categoryToEdit: {},
  productId: undefined,
  menu: [],
  settings: {},
  food: [],
  drinks: [],
  cigaretteToEdit: {},
  tigari: [],
  allergens: [],
  events: [],
  infos: {
    name: '',
    description: {},
    tableNo: 0
  },
  allergensToEdit: {},
  images: [],
  isPhotosModal: false,
  currentSection: '',
  moveAfterCategory: undefined,
  isSaving: false,
  dataCopy: undefined,
  isImageUpload: false,
  newProductImg: '',
  cart: [],
  searchProductString: ''
}

export const mutations = {
  SET_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_MENU_STATE(state, { key, value }) {
    state[key] = value
  },
  SET_MENU (state, { menu, type }) {
    state[type] = menu
  },
  SET_CURRENT_SECTION(state, section) {
    state.currentSection = section
  },
  ADD_INFOS(state, { key, name, value }) {
    key ? (state.infos[key][name] = value) : (state.infos[name] = value)
  },
  SET_PHOTOS_MODAL(state, value) {
    state.isPhotosModal = value
  },
  SET_IMAGES(state, images) {
    state.images = images
  },
  DELETE_IMG(state, img) {
    const index = state.images.indexOf(img)
    state.images.splice(index, 1)
  },
  //category mutations
  SET_CATEGORY_TO_EDIT(state, { category = {}, id }) {
    state.categoryToEdit = category
    state.categoryId = id
  },
  ADD_NEW_CATEGORY(state, category) {
    const newCategory = {
      id: uid(),
      category,
      products: []
    }
    state[state.currentSection] = [...state[state.currentSection], newCategory]
  },
  UPDATE_CATEGORY_NAME(state, category) {
    const section = state.currentSection
    const index = state[section].findIndex(
      (obj) => obj.category.ro === state.categoryToEdit.ro
    )
    state[section][index].category = category
  },
  MOVE_CATEGORY(state, categoryName) {
    state.moveAfterCategory = categoryName
  },
  MOVE_AFTER_CATEGORY(state) {
    let data = [...state[state.currentSection]]
    const index = getIndexOfCategoryById(data, state.categoryId)
    const category = data[index]
    data = state[state.currentSection].filter(({ id }) => id !== state.categoryId)
    switch (state.moveAfterCategory) {
      case 'Prima':
        data.unshift(category)
        break
      case 'Ultima':
        data.push(category)
        break
      default:
        const afterIndex = data.findIndex(
          (obj) => obj.category.ro === state.moveAfterCategory
        )
        data.splice(afterIndex + 1, 0, category)
        break
    }
    state[state.currentSection] = data
  },

  DELETE_CATEGORIE(state) {
    const data = [...state[state.currentSection]]
    const index = getIndexOfCategoryById(data, state.categoryId)
    data.splice(index, 1)
    state[state.currentSection] = [...data]
  },

  //product mutations
  SET_PRODUCT_ID(state, id) {
    state.productId = id
  },

  ADD_PRODUS(state, produs) {
    const data = [...state[state.currentSection]]
    const index = getIndexOfCategoryById(data, state.categoryId)
    produs.id = uid()
    state[state.currentSection][index].products.push(produs)
    state.newProductImg = ''
  },

  UPDATE_PRODUS(state, produs) {
    const section = state.currentSection
    const data = [...state[state.currentSection]]
    const indexCategory = getIndexOfCategoryById(data, state.categoryId)
    const indexProdus = getIndexOfProductById(data, state.categoryId, state.productId)
    state[section][indexCategory].products[indexProdus] = produs
  },

  UPDATE_PRODUS_IMG(state, img) {
    const section = state.currentSection
    const data = [...state[section]]
    const indexCategory = getIndexOfCategoryById(data, state.categoryId)
    const indexProdus = getIndexOfProductById(data, state.categoryId, state.productId)
    if (indexProdus === -1) {
      state.newProductImg = img
      return
    }
    state[section][indexCategory].products[indexProdus].img = img
    state.isPhotosModal = false
  },

  SET_PRODUCT_CATEGORY(state, categoryId) {
    const section = state.currentSection
    const data = [...state[section]]
    const indexCategory = getIndexOfCategoryById(data, state.categoryId)
    const indexProdus = getIndexOfProductById(data, state.categoryId, state.productId)
    const indexNewCategory = state[section].findIndex((obj) => obj.id === categoryId)
    const produs = state[section][indexCategory].products[indexProdus]
    state[section][indexNewCategory].products.push(produs)
    state[section][indexCategory].products.splice(indexProdus, 1)
  },

  CHANGE_PRODUCT_ORDER(state, productId) {
    const section = state.currentSection
    const data = [...state[section]]
    const indexCategory = getIndexOfCategoryById(data, state.categoryId)
    const indexProdus = getIndexOfProductById(data, state.categoryId, state.productId)
    const indexSelectedProduct = getIndexOfProductById(data, state.categoryId, productId)
    const produs = state[section][indexCategory].products[indexProdus]
    state[section][indexCategory].products.splice(indexProdus, 1)
    if (productId === 'primul') {
      state[section][indexCategory].products.unshift(produs)
      return
    }
    state[section][indexCategory].products.splice(indexSelectedProduct, 0, produs)
  },

  DELETE_PRODUS(state) {
    // find the index of the product to be deleted and remove it from the array
    let data = [...state[state.currentSection]]
    const categoryIndex = getIndexOfCategoryById(data, state.categoryId)
    const productIndex = getIndexOfProductById(data, state.categoryId, state.productId)
    state[state.currentSection][categoryIndex].products.splice(productIndex, 1)
  },

  //cigarette mutations
  SET_TIGARI_TO_EDIT(state, cigarette) {
    state.cigaretteToEdit = cigarette
  },

  ADD_NEW_CIGARETTE_BRAND(state, tigari) {
    const newBrand = {
      id: uid(),
      ...tigari
    }
    state[state.currentSection] = [...state[state.currentSection], newBrand]
  },

  UPDATE_TIGARA(state, cigarette) {
    const id = state.cigaretteToEdit.id
    const index = state.tigari.findIndex((o) => o.id === id)
    state.tigari[index] = cigarette
  },

  DELETE_MARCA_TIGARA(state) {
    const id = state.cigaretteToEdit.id
    const index = state.tigari.findIndex((o) => o.id === id)
    state.tigari.splice(index, 1)
  },

  //allergen mutations
  SET_ALERGENI_TO_EDIT(state, name) {
    state.allergensToEdit = state.allergens.filter((obj) => obj.name === name)[0]
  },

  ADD_ALERGENI(state, data) {
    data.id = uid()
    state.allergens.push(data)
  },

  UPDATE_ALERGENI_ARRAY(state, data) {
    const section = state.currentSection
    const index = state[section].findIndex(
      (obj) => obj.name === state.allergensToEdit.name
    )
    state[section][index] = data
  },

  //cart mutations
  ADD_TO_CART(state, { categoryId, productId }) {
    const index = getIndexOfCartProductById(state.cart, productId)
    if (index >= 0) {
      const productInCart = state.cart[index]
      productInCart.count++
    } else {
      const currentSection = state[state.currentSection]
      const categoryIndex = getIndexOfCategoryById(currentSection, categoryId)
      const productIndex = getIndexOfProductById(currentSection, categoryId, productId)
      const product = {
        ...currentSection[categoryIndex].products[productIndex],
        count: 1
      }
      state.cart.push(product)
    }
  },
  ADD_CIGARETTE_TO_CART(state, productId) {
    const index = getIndexOfCartProductById(state.cart, productId)
    if (index >= 0) {
      const productInCart = state.cart[index]
      productInCart.count++
    } else {
      const currentSection = state[state.currentSection]
      const productIndex = currentSection.findIndex((obj) => obj.id === productId)
      const cigarette = currentSection[productIndex]
      const product = {
        id: cigarette.id,
        name: { ro: cigarette.brand, en: cigarette.brand },
        price: cigarette.price,
        id_pos: cigarette.id_pos,
        count: 1
      }
      state.cart.push(product)
    }
  },
  REMOVE_FROM_CART(state, productId) {
    const index = getIndexOfCartProductById(state.cart, productId)
    state.cart.splice(index, 1)
  },
  INCREMENT_PRODUCT_COUNT(state, productId) {
    const index = getIndexOfCartProductById(state.cart, productId)
    state.cart[index].count += 1
  },
  DECREMENT_PRODUCT_COUNT(state, productId) {
    const index = getIndexOfCartProductById(state.cart, productId)
    if (state.cart[index].count > 1) {
      state.cart[index].count -= 1
    }
  },
  DELETE_ALLERGEN (state, id) {
    const index = state.allergens.findIndex(o => o.id === id)
    state.allergens.splice(index, 1)
  },
  SET_SEARCHING_PRODUCT (state, string) {
    state.searchProductString = string.toLowerCase()
  },
  UPDATE_PRODUCT_STATUS (state, {id, value}) {
    const food = state.food.reduce((acc, { category, id, products }) => {
      acc.push(...products)
      return acc
    }, [])
    const drinks = state.drinks.reduce((acc, { category, id, products }) => {
      acc.push(...products)
      return acc
    }, [])
    const products = [...food, ...drinks]
    products.forEach(o => {
      if (o.id === id) {
        o.enabled = value
      }
    })
  }
}

//ACTIONS
export const actions = {
  async get_menu({ commit, rootState }, type) {
    try {
      const id = rootState.restaurant_id
      const { data } = await axios(
        `${api.menu}?id=${rootState.auth.user?.role || id}`
      )
      const dataToFetch = ['food', 'infos', 'drinks', 'allergens', 'tigari', 'settings']
      commit('SET_MENU_STATE', {key: 'menu', value: data})
      dataToFetch.forEach((type) => {
        commit('SET_MENU', { type, menu: data[type]})
      })
    } catch (e) {}
  },
  async update_menu({ state, commit }, payload) {
    try {
      commit('SET_MENU_STATE', { key: 'isSaving', value: true })
      const section = state.currentSection
      let categoriesId = state[section].map((o) => o.id)
      const setCategoriesId = new Set(categoriesId)
      if (setCategoriesId.size < categoriesId.length) {
        console.log('Duplicates found in Categories', categoriesId)
        throw new Error('ID CATEGORIES ERROR')
      }
      let productIds = state[section].reduce((acc, curr) => {
        curr.products?.forEach((o) => acc.push(o.id))
        return acc
      }, [])
      const setProductsId = new Set(productIds)
      if (setProductsId.size < productIds.length) {
        console.log('Duplicates found in Products')
        throw new Error('ID PRODUCTS ERROR')
      }
      await axios.put(`${api.menu}?type=${section}`, state[section])
      commit('SET_SIDE_COMPONENT', '')
      commit('SET_MENU_STATE', { key: 'isSaving', value: false })
      payload?.commitName &&
        setTimeout(() => {
          commit(payload.commitName, payload.value)
        }, payload.time || 0)
    } catch (err) {
      console.log(err)
    }
  },

  async upload_logo({ state, commit }, formData) {
    try {
      await axios.put(`${api.menu}/logo`, formData)
    } catch (err) {
      console.log(err)
    }
  },

  async delete_logo({ state, commit }) {
    try {
      await axios.delete(`${api.menu}/logo`)
    } catch (err) {
      console.log(err)
    }
  },
  async get_images({ commit }) {
    try {
      const { data } = await axios(api.files)
      commit('SET_IMAGES', data)
    } catch (e) {}
  },
  async upload_img({ commit, dispatch }, formData) {
    try {
      commit('SET_MENU_STATE', { key: 'isImageUpload', value: true })
      await axios.post(api.files, formData)
      commit('SET_MENU_STATE', { key: 'isImageUpload', value: false })
      dispatch('get_images')
    } catch (e) {}
  },

  async delete_img({ commit }, img) {
    try {
      await axios.delete(`${api.files}?image=${img}`)
      commit('DELETE_IMG', img)
    } catch (e) {}
  },

  async set_product_status ({commit}, {id, value, categoryId, type}) {
  	try {
      const url = `${api.menu}/status?type=${type}&productId=${id}&categoryId=${categoryId}`
  		await axios.put(url, {enabled: value})
      commit('UPDATE_PRODUCT_STATUS', {id, value})
  	}
  	catch (err) {}
  },

  // todo move to order along with cart object in state
  async place_order ({state, rootState, commit}, {notes}) {
  	try {
      const id = rootState.restaurant_id
      const products = state.cart
        .filter(o => !o.ordered)
        .map(o => ({
          id: o.id_pos,
          quantity: o.count
        }))
      // todo de trimis nr mesei ce ar fi salvat in url cand se scaneaza QR
  		const { data } = await axios.post(api.orders, {id, table: 5, products, notes})
      // todo save in localStorage the order for the refresh case and remove quantity and remove product option
      // todo timestamp in localstorage cu clear data la 4 ore?
      // in state cart sa trecem prin fiecare si sa marcam ca si ordered
      state.cart.forEach(obj => {
        obj.ordered = true
      })
      commit('SET_MODAL_NAME', 'orderWaiting', { root: true })
  	}
  	catch (err) {}
  },
  async get_check ({state, rootState, commit}, payment_type) {
    try {
      const id = rootState.restaurant_id
      const { data } = await axios.post(api.notifications, {id, table: 5, payment_type})
      // todo save in localStorage the order for the refresh case and remove quantity and remove product option
      // todo timestamp in localstorage cu clear data la 4 ore?
      commit('SET_MODAL_NAME', 'inform', { root: true })
      commit('SET_STATE', { key: 'checkRequest', value: true })
    }
    catch (err) {}
  }
}

export const getters = {
  usedImages: (state) => {
    let imgs = []
    state.food.forEach((obj) => {
      obj.products.forEach(({ img }) => {
        img && imgs.push(img)
      })
    })
    state.drinks.forEach((obj) => {
      obj.products.forEach(({ img }) => {
        img && imgs.push(img)
      })
    })
    return imgs
  },

  // filter menu based on the language selected
  food: (state, getters, { lang }) => {
    return state.food.map(({ category, id, products }) => {
      return {
        category: category[lang],
        categoryId: id,
        products: products.map((o) => ({
          ...o,
          categoryId: id,
          name: o.name[lang],
          description: o.description[lang]
        }))
      }
    })
  },

  cart: (state, getters, { lang }) => {
    return state.cart.map((product) => {
      return {
        ...product,
        name: product.name[lang]
      }
    })
  },

  categories: (state, getters, { lang }) => {
    if (state.currentSection === 'food' || state.currentSection === 'drinks') {
      return state[state.currentSection].map((cat) => ({
        name: cat.category[lang],
        id: cat.id
      }))
    }
  },

  categoriesRo: (state) => {
    const data = state[state.currentSection].map((obj) => obj.category.ro)
    const indexCategorieCurenta = state[state.currentSection].findIndex(
      (obj) => obj.category.ro === state.categoryToEdit.ro
    )
    data.splice(indexCategorieCurenta, 1)
    return data
  },

  drinks: (state, getters, { lang }) => {
    return state.drinks.map(({ category, products, id }) => {
      return {
        categoryId: id,
        category: category[lang],
        products: products.map((o) => ({
          ...o,
          categoryId: id,
          name: o.name[lang] || o.name.ro,
          description: o.description[lang] || o.description.ro
        }))
      }
    })
  },

  foodAndDrinks: (state, getters, { lang }) => {
    const food = state.food.reduce((acc, { id, products }) => {
      const copy = products.map(o => ({...o}))
      copy.forEach(o => {
        o.categoryId = id
        o.type = 'food'
        if (o.name?.ro) {
          o.name = o.name?.ro
        }
      })
      acc.push(...copy)
      return acc
    }, [])
    const drinks = state.drinks.reduce((acc, { id, products }) => {
      const copy = products.map(o => ({...o}))
      copy.forEach(o => {
        o.categoryId = id
        o.type = 'drinks'
        if (o.name?.ro) {
          o.name = o.name?.ro
        }
      })
      acc.push(...copy)
      return acc
    }, [])
    return [...food, ...drinks].filter(o => o.name?.toLowerCase().includes(state.searchProductString))
  },

  allergens: (state, getters, { lang }) => {
    return state.allergens
  },

  products: (state) =>
    state.food.reduce((acc, { products }) => {
      products.forEach(({ name, id }) => {
        acc.push({ name: name.ro, id })
      })
      return acc
    }, []),

  productsFromCurrentCategory: (state) => {
    const section = state.currentSection
    const products = state[section]
      .filter((obj) => obj.id === state.categoryId)
      .map((obj) => obj.products)[0]
    return [
      { id: 'primul', name: { ro: 'Primul' } },
      ...products.filter((obj) => obj.id !== state.productId)
    ]
  },

  isEmptyCategory: (state) => {
    const index = getIndexOfCategoryById(
      [...state[state.currentSection]],
      state.categoryId
    )
    return !!state[state.currentSection][index]?.products.length
  },

  selectedProduct: (state) => {
    const data = [...state[state.currentSection]]
    const index = getIndexOfCategoryById(data, state.categoryId)
    const productIndex = getIndexOfProductById(data, state.categoryId, state.productId)
    return state[state.currentSection][index].products[productIndex]
  },
  cartTotalProducts: (state) => {
    return state.cart.filter(o => !o.ordered).reduce((acc, curr) => acc + curr.count, 0)
  },
  cartTotalPrice: (state) => {
    return state.cart.reduce((acc, curr) => acc + curr.count * curr.price, 0)
  }
}

function getIndexOfCategoryById(data, id) {
  return data.findIndex((obj) => obj.id === id)
}

function getIndexOfProductById(data, id, productId) {
  const index = getIndexOfCategoryById(data, id)
  return data[index].products.findIndex((obj) => obj.id === productId)
}

function getIndexOfCartProductById(cart, productId) {
  return cart.findIndex((product) => product.id === productId)
}
