<template>
	<div class="file-upload">
    <label :for="name">
      <span>{{ label || name}}</span>
      <input :id="name"
             @change="handleFile"
             type="file" />
    </label>
    <img v-if="!!image"
         style="width: 200px"
         :src="image"
         alt=""
         ref="previewElm" />
<!--    <button @click="handleRemove">REMOVE</button>-->
	</div>
</template>

<script setup>
  import { ref } from 'vue'
  const props = defineProps({
    name: String,
    label: String,
    image: String
  })
  const preview = ref(false)
  const previewElm = ref(null)
  const formData = new FormData()
  const emit = defineEmits(['upload', 'remove'])

  function handleFile (input) {
    preview.value = true;
    formData.delete('file')
    formData.append('file', input.target.files[0]);
    emit('upload', formData)
    previewImage(input);
  }

  function previewImage (input) {
    const reader = new FileReader();
    reader.readAsDataURL(input.target.files[0]);
    reader.onload = function (e) {
      previewElm.value.setAttribute('src', e.target.result);
    }
  }

  function handleRemove () {
    preview.value = false;
    formData.delete('image');
    emit('remove')
    previewElm.value.setAttribute('src', '');
  }
</script>

<style lang="scss">
	.file-upload {

	}
</style>
