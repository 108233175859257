const version = `v2`
export default {
  menu: `/api/${version}/menu`,
  events: `/api/${version}/events`,
  reviews: `/api/${version}/reviews`,
  login: `/api/${version}/auth/login`,
  logout: `/api/${version}/auth/logout`,
  checkLogin: `/api/${version}/auth/check-login`,
  files: `/api/${version}/files`,
  orders: `/api/${version}/orders`,
  notifications: `/api/${version}/notifications`,
  settings: `/api/${version}/settings`,
  ingredients: `/api/${version}/ingredients`,
}
