import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Welcome from '../views/Welcome.vue'
import LoginView from '../views/LoginView.vue'
import MenuLayout from '../layouts/MenuLayout'
import FoodView from '../views/FoodView'
import DrinksView from '../views/DrinksView'
import TigariView from '../views/TigariView'
import AllergensView from '../views/AllergensView'
import FeedbackView from '../views/FeedbackView'
import CartView from '../views/CartView'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    component: () => import('../layouts/DashboardLayout'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        redirect: '/dashboard/food'
      },
      {
        path: 'events',
        meta: {
          section: 'events'
        },
        component: () => import('../views/dashboard/Events')
      },
      {
        path: 'food',
        meta: {
          section: 'food'
        },
        component: () => import('../views/dashboard/Mancare')
      },
      {
        path: 'settings',
        meta: {
          section: 'settings'
        },
        component: () => import('../views/dashboard/Setari')
      },
      {
        path: 'ingredients',
        meta: {
          section: 'ingredients'
        },
        component: () => import('../views/dashboard/IngredientsView')
      },
      {
        path: 'drinks',
        meta: {
          section: 'drinks'
        },
        component: () => import('../views/dashboard/Bauturi')
      },
      {
        path: 'tigari',
        meta: {
          section: 'tigari'
        },
        component: () => import('../views/dashboard/Tigari')
      },
      {
        path: 'allergens',
        meta: {
          section: 'allergens'
        },
        component: () => import('../views/dashboard/Alergeni')
      },
      {
        path: 'foto',
        name: 'foto',
        component: () => import('../views/dashboard/Fotografii')
      },
      {
        path: 'feedback-details',
        name: 'feedback-details',
        component: () => import('../views/dashboard/Feedback')
      }
    ]
  },
  {
    path: '/:restaurant_id',
    children: [
      {
        path: '',
        name: 'welcome',
        component: Welcome
      },
      {
        path: 'cart',
        name: 'cart',
        component: MenuLayout,
        children: [
          {
            path: '',
            name: 'cart',
            component: CartView
          }
        ]
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: MenuLayout,
        children: [
          {
            path: '',
            name: 'feedback',
            component: FeedbackView
          }
        ]
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('../views/EventsView.vue')
      },
      {
        path: 'notify-waiter',
        name: 'notify-waiter',
        component: () => import('../views/NotifyWaiterView.vue')
      },
      {
        path: 'menu',
        name: 'menu',
        component: MenuLayout,
        children: [
          {
            path: '',
            name: 'menu',
            redirect: 'food'
          },
          {
            path: 'food',
            name: 'food',
            component: FoodView
          },
          {
            path: 'drinks',
            name: 'drinks',
            component: DrinksView
          },
          {
            path: 'tigari',
            name: 'tigari',
            component: TigariView
          },
          {
            path: 'allergens',
            name: 'allergens',
            component: AllergensView
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const id = to.params.restaurant_id
  const table = to.query.table
  to.name && store.commit('SET_CURRENT_SECTION', to.name)
  id && store.commit('SET_RESTAURANT_ID', id)
  table && store.commit('SET_RESTAURANT_TABLE', table)
  window.scrollTo(0, 0)
  if (to.name === 'login') {
    return next()
  }
  to.meta.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', next)
    : next()
})

export default router
