<template>
  <header
    class="main-header fixed left0 top0 z99 w100"
    @click="closeCategoriesModal"
  >
    <div class="flex align-center flex-between">
      <LogoImg />
      <nav class="flex flex-between align-center">
<!--        <router-link class="navigation-feedback" :to="`/${id}/feedback`">-->
<!--          <img src="../assets/feedback-icon.png" alt="feedback icon" />-->
<!--        </router-link>-->

<!--        <router-link class="cart-link relative" :to="`/${id}/cart`">-->
<!--          <img src="../assets/cart-icon.png" alt="cart" />-->
<!--          <span cy="cart-total-products"-->
<!--              class="absolute circle flex-center fw-700"-->
<!--          >-->
<!--            {{ cartTotalProducts }}-->
<!--          </span>-->
<!--        </router-link>-->

<!--        <router-link :to="`/${id}`">-->
<!--          <button-->
<!--              class="bg-transparent no-border flex align-center"-->
<!--              @click="changeLanguage"-->
<!--          >-->
<!--            <img v-if="lang === 'ro'" src="../assets/flag-en.svg" alt="" />-->
<!--            <img v-else src="../assets/flag-ro.svg" alt="" />-->
<!--          </button>-->
<!--        </router-link>-->
      </nav>
    </div>
    <p class="notification absolute text-center w100"
       :class="{'active-toast': toastNotif}">
      {{ cartText.toastConfirmOrder }}
    </p>

    <i class="absolute left0 z99 w100" />

  </header>
</template>

<script setup>
  import LogoImg from '../components/LogoImg'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  const { state, commit, getters } = useStore()
  const id = computed(() => state.restaurant_id)
  const toastNotif = computed(() => state.toastNotif)
  const lang = localStorage.getItem('lang')
  const cartTotalProducts = computed(() => getters.cartTotalProducts)
  const cartText = computed(() => getters.cartText)
  function changeLanguage() {
    commit('SET_VALUE', { key: 'isChangingLanguage', value: true })
  }

  function closeCategoriesModal() {
    commit('SHOW_CATEGORIES_MODAL', false)
    commit('SHOW_CALL_WAITER_MODAL', false)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .main-header {
    > div {
      padding: 4px 16px;
      background-color: $dark;
      z-index: 2;
      position: relative;
    }
    > i {
      height: 20px;
      bottom: 1px;
      transform: translateY(88%);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzc3IiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMzc3IDIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTAzLjUgMTAuNUM1OS43NjQ5IDI1Ljk2NzMgMTggMTUuMzMzMyAwLjUgMy40OTk5OFYwSDM3N1Y0LjVDMzc0LjE2NyAzLjE2NjY3IDM3Mi4xMTUgMi44NjI3MSAzNTMuNSAxMy41QzMzOS41IDIxLjUgMzE4LjUgMTcgMzA2LjY0NiAxMC41QzI5MC4yMjIgMS40OTQwMSAyNzIuMTQ2IDAuNDk5OTk0IDI1My42NDYgMTAuNUMyMjQuNTEgMjYuMjQ5MyAxOTUgMTguNSAxODAgMTJDMTcwLjc5NiA4LjAxMTc1IDE0NC41IC00IDEwMy41IDEwLjVaIiBmaWxsPSIjMUMzNjJCIi8+Cjwvc3ZnPgo=');
    }

    .navigation-cart {
      margin-right: 8px;
    }
    .navigation-feedback {
      margin-right: 14px;
    }
    img {
      height: 30px;
    }
    .cart-link {
      img {
        height: 40px;
      }
      span {
        width: 21px;
        height: 21px;
        left: 23px;
        top: 4px;
        background: $alternative;
        font-size: 12px;
        color: $dark;
        padding: 1px;
        bottom: 12px;
      }
    }
    div a:not(:last-child) {
      margin-right: 16px;
    }
    .notification {
      top: 0;
      left: 0;
      z-index: 1;
      padding: 24px 16px 8px;
      margin-top: 0;
      font-size: 2rem;
      background-color: rgba($toast, 1);
      color: $alternative;
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      &.active-toast {
        opacity: 1;
        transform: translateY(90%);
      }
    }
  }
</style>
