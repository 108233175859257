<template>
  <main class="welcome-view">
    <LogoImg />

    <p>
      {{ infos.description[language || 'ro'] }}
    </p>

    <nav cy="categories-list"
         class="flex flex-wrap flex-between">
      <div v-for="({name, route, img, showIf}, index) in categories"
           :key="index">
        <router-link :to="route" :class="{hide: showIf && !settings[showIf]}">
          <img :src="img" alt="" />
          <span>{{ name[language || 'ro'] }}</span>
        </router-link>
      </div>
    </nav>

    <div class="language-selection"
         cy="language-selector">
      <button @click="handleLanguage('ro')" cy="lang-ro-btn">
        <img src="../assets/flag-ro.svg" alt="" />
        <br />
        <span :class="{selected: language === 'ro'}">RO</span>
      </button>
      <button @click="handleLanguage('en')">
        <img src="../assets/flag-en.svg" alt="" />
        <br />
        <span :class="{selected: language === 'en'}">EN</span>
      </button>
    </div>
  </main>
</template>

<script setup>
  import { useStore } from 'vuex'
  import {computed, onMounted} from 'vue'
  import { useRouter } from 'vue-router'
  import LogoImg from '../components/LogoImg'
  import foodIcon from '../assets/icons/romantic-dinner.png'
  import drinksIcon from '../assets/icons/drink_romantic.png'
  import eventsIcon from '../assets/icons/microphone.png'
  import bellIcon from '../assets/icons/wedding-bells.png'

  const router = useRouter()
  const { state, commit, dispatch } = useStore()
  const infos = computed(() => state.menu.infos)
  const id = computed(() => state.restaurant_id)
  dispatch('get_menu', 'infos')
  const table = router.currentRoute.value.query.table
  const language = computed(() => state.lang)
  const settings = computed(() => state.menu.settings)

  const categories = [
    {
      name: {ro: 'Mancare', en: 'Food'},
      route: `/${id.value}/menu/food${table ? '?table=' + table : ''}`,
      img: foodIcon,
    },
    {
      name: {ro: 'Bauturi', en: 'Drinks'},
      route: `/${id.value}/menu/drinks${table ? '?table=' + table : ''}`,
      img: drinksIcon
    },
    {
      name: {ro: 'Evenimente', en: 'Events'},
      route: `/${id.value}/events`,
      img: eventsIcon,
      showIf: "events"
    },
    {
      name: {ro: 'Ospatar', en: 'Waiter'},
      route: `/${id.value}/notify-waiter`,
      img: bellIcon
    },
  ]

  function handleLanguage (lang) {
    commit('SET_LANGUAGE', lang)
  }

  onMounted(() => {
    commit('SET_LANGUAGE', 'ro')
  })
</script>

<style lang="scss">
  .welcome-view {
    padding: 48px;
    text-align: center;
    > p {
      font-family: Capsuula, sans-serif;
      font-size: 3rem;
      img {
        width: 80%;
      }
    }
    nav {
      max-width: 200px;
      margin: 0 auto 36px;
      div {
        margin-bottom: 24px;
      }
      a {
        width: 80px;
        display: block;
        font-size: 2rem;
        text-decoration: none;
        img {
          width: 100%;
        }
        span {
          font-family: Capsuula, sans-serif;
          font-size: 2.4rem;
        }
      }
    }
    button {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      img {
        height: 26px;
      }
      span {
        font-family: Capsuula, sans-serif !important;
        &.selected {
          text-decoration: underline;
        }
      }
      &:first-child {
        margin-right: 24px;
      }
    }
  }
</style>
